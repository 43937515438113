@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  src: url('https://cloud.modyocdn.com/uploads/6ecb36f0194c11e78db75d7f275693d5/original/AvenirLTStd-Book-69c4b96309a3548d9b875986c26f1c634ebd04b15933df2d9d5ff0807d7785ef.woff') format('woff2');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  src: url('https://cloud.modyocdn.com/uploads/73a4ab70194c11e7b93cf36b64cdcbe9/original/AvenirLTStd-Light-12a71ce50e1e0afb80361f279bd7fdc2bc15ee91533d150d6fa95a0017ce4dce.woff') format('woff2');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  src: url('https://cloud.modyocdn.com/uploads/6c731620194c11e793d43d531bd2724b/original/AvenirLTStd-Black-e5e005787909e065b4fa0df00b4608afe46473998f9d135b75f1c8b7b3988e26.woff') format('woff2');
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

#logoDiv{
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 25px;
  justify-content: space-between;
}
#logo{
  width: 180px;
  text-align: left;
}

#login-div{
  height: 100vh;
  background-color: lavender;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

#login-div img{
  margin-top: 5%;
}

#login-div > div{
  display: grid;
  text-align: center;
  margin: 0 auto;
  height: 200px;
  width: 350px;
  background-color: #fff;
  align-items: center;
  justify-items: center;
  border-radius: 8px;
  box-shadow: 10px 10px 60px #555;
  margin-top: 3%;
}
#loginButton{
  cursor: pointer;
}
#loginButton:hover{
  background-color: #308999
}
#title{
  font-family: 'Avenir';
  font-size: 30px;
  padding-left: 25px;
}

#title > a{
  text-decoration: none;
  color: #333;
}

#title > a:hover{
  color: #308999!important;
}

.base-div{
    display: flex;
    margin:0 auto;
    max-width:1100px;
    column-gap: 4%;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 50px;
}

.base-div > div{
    padding: 50px;
    border: 1px solid #dee2e6;
    border-radius: 7px;;
    flex: 0 43%;
    flex-grow: 1;
}

.body-img > div > i{
    padding-right: 7px;
}

.img-home{
    width:100%;
}

/*FA FA*/
#icons-div{
    display:flex;
    flex-direction: row;
}

#liked{
    color: red;
    transition-duration: 1s;
    background-position: -2800px 0;  
}

.heart{
    width: 100px;
    height: 100px;
    background: url("https://cssanimation.rocks/images/posts/steps/heart.png") no-repeat;
    background-position: 0 0;
    cursor: pointer;
    transition: background-position 1s steps(28);
    transition-duration: 0s;
    margin-left: -35px;
    margin-top: -35px;
  }

.fa-comment-o{
    margin-left:-35px;
    cursor: pointer;
}

/* ALERT MESSAGE */
.message-div{
  position: fixed;
  left:50%;
  top: 5%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
}

.message {
  flex: 80%;
  text-align: start;
  font-weight: bold;
}

#show {
  visibility: visible;
  animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
}
#hide {
  visibility: hidden;
}
@keyframes fadeIn {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

/* COMMENTS */
.rightSide {
    display: grid;
    place-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .rightSide .addCommentContainer {
    width: 100%;
    display: flex;
    place-items: center;
    margin-top: 25px;
    column-gap:5px
  }
  
  .addCommentContainer input,
  button {
    width: 200px;
    height: 50px;
    border-radius: 8px;
    border: none;
  }
  
  .addCommentContainer button {
    background-color: #308999;
    color: white;
    cursor: pointer;
    opacity: 0.8;
  }
  .addCommentContainer button:hover {
    opacity: 1;
  }
  
  .addCommentContainer input {
    border: 2px solid #308999;
    width: 100%;
  }
  
  .rightSide .listOfComments {
    width: 100%;
    align-items: center;
  }

  .listOfComments{
  overflow-y: scroll;
  overflow-x: hidden;
  height: auto;
  max-height: 200px;
  }

  .message {
    text-align: left;
    width: 100%;
    justify-content: flex-end;
    margin-left: 5px;
    padding-top: 5px;
    border-top: 1px solid #dee2e6;
  }

.message-content{
    display: flex;
    width: 100%;
    overflow-wrap: anywhere;
}

.author{
    width: auto;
    min-inline-size: fit-content;
}

.commentText {
    width: 100%;
    margin: 0px;
    padding-left: 5px;
    text-transform: capitalize;
    font-weight: 100;
    margin-right: 10px;
}

.time {
    margin: 1px 0 10px 0px;
    font-size: 12px;
    text-transform: capitalize;
}

/*IMG CAROUSEL*/
.container-slider {
  max-width: 100%;
  height: 500px;
  max-height:500px;
  margin: 0 0 7px 0;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 700px){
  .container-slider {
    margin: 100px 10px 0;
  }
}
.slide {
  width: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  object-fit: cover;
  max-width: 100%;
  min-width: 5px;
  width: auto;
  height: 500px;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}


.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(32, 32, 32);
}
/*ADMIN*/
.file-card{
    /*background-color: #1997a1;*/
    border: 3px dashed #dee2e6;
    min-height: 230px;
    display: flex;
    margin:0 auto;
    max-width:800px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.file-inputs{
    position: relative;
    margin-bottom: 1.5em;
}

.file-inputs > input{
    cursor: pointer;
}

.admin-supported, .admin-info{
    padding: 0px;
    margin: 10px;
}

#uploadImage:hover{
  background-color:#308999;
  cursor: pointer;
}

#messageUploadedFlash {
  display: none;
  color: red;
}